<template>
  <div :class="klass">
    <a
      class="sl-btn"
      href="#"
      :aria-label="text + ' link'"
      @click.prevent="goToOverview()"
    >
      <fa-icon icon="chevron-left" class="fa-xs" />
      {{ text }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StagelineBackButton',
  props: {
    text: {
      type: String,
      default: 'Back',
    },
    klass: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'currentSlide',
    ]),
  },
  methods: {
    async goToOverview() {
      if (this.$router.currentRoute.path.includes('overview')) {
        await this.$router.push('/stageline-v2')
      } else {
        await this.$router.push({ name: 'overview', params: {
            companyId: this.company.id,
            slideId: this.currentSlide?.id,
          },
        })
      }
    },
  },
}
</script>
<style scoped lang="scss">
.sl-btn {
  color: black;
}
</style>
