var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.klass }, [
    _c(
      "a",
      {
        staticClass: "sl-btn",
        attrs: { href: "#", "aria-label": _vm.text + " link" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.goToOverview()
          },
        },
      },
      [
        _c("fa-icon", {
          staticClass: "fa-xs",
          attrs: { icon: "chevron-left" },
        }),
        _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }